<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <!-- <div class="topid">
          <div id="walletAddress">{{ shortWalletAddress }}</div>
          <div id="walletBalanceTop">金额: {{ walletBalanceTop }} USDT</div>
          <div id="pointsBalance">积分: {{ points }}</div>
        </div> -->
      </div>
      <div class="main">
        <div class="itemheader">
          <img src="@/assets/images/ban-2.png" alt="">
          <div>
            <h2>SCPs ECOL OGY</h2>
            <p>开创区块链新时代</p>
          </div>
        </div>

        <h3 class="title">订单详情</h3>
        <div class="card">
          <form @submit.prevent="handleSubmit">
            <label for="address">收货地址:</label>
            <input type="text" id="address" v-model="addressInfo" required class="input-field" />
            <label for="name">姓名:</label>
            <input type="text" id="name" v-model="name" required class="input-field" />
            <label for="phone">手机号码:</label>
            <input type="tel" id="phone" v-model="phoneNumber" required class="input-field" />
            <button type="submit" v-if="walletAddress && !submittedWallets.has(walletAddress) && points >= 12800">提交</button>
            <div v-if="points < 12800" class="error-message"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { usdtTokenAddress, usdtAbi, contractAddresss, contractABII } from '@/js/contract.js';

export default {
  name: 'item',
  data() {
    return {
      addressInfo: '',
      phoneNumber: '',
      name: '',
      walletAddress: '未连接',
      shortWalletAddress: '未连接',
      walletBalanceTop: '0',
      points: 0, // 添加积分属性
      submittedWallets: new Set() // 存储已提交的钱包地址
    };
  },
  mounted() {
    this.connectWallet();
    this.loadPoints(); // 加载积分
  },
  beforeDestroy() {
    this.savePoints(); // 保存积分
  },
  methods: {
    async connectWallet() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();

          // 使用 ERC20 合约获取 USDT 余额
          const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, provider);
          const balance = await usdtContract.balanceOf(address);
          const balanceInUsdt = ethers.utils.formatUnits(balance, 6); // USDT 有 6 位小数
          const balanceInUsdtInt = Math.floor(balanceInUsdt); // 将余额取整

          this.walletAddress = address;
          this.shortWalletAddress = `地址:${address.slice(0, 5)}...${address.slice(-4)}`;
          this.walletBalanceTop = balanceInUsdtInt; // 显示整数余额

        } catch (error) {
          console.error(error);
          alert('连接钱包失败');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async handleSubmit() { // 将 handleSubmit 方法标记为 async
      if (this.points < 12800) {
        alert('积分不足，请先充值积分。');
        return;
      }

      // 添加确认框
      const confirmed = confirm('提交将扣除12800积分，您确定要继续吗？');
      if (!confirmed) {
        return; // 如果用户取消，则不进行后续操作
      }

      // 扣除12800积分
      this.points -= 12800;
      this.savePoints(); // 保存更新后的积分

      // 提交收货地址、姓名和电话号码到智能合约
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddresss, contractABII, signer);

        // 调用智能合约的 storeAddress 方法
        const tx = await contract.storeAddress(this.addressInfo, this.phoneNumber, this.name);
        await tx.wait(); // 等待交易确认

        // 将钱包地址添加到已提交的集合中
        this.submittedWallets.add(this.walletAddress);

        // 清空输入框
        this.addressInfo = '';
        this.phoneNumber = '';
        this.name = '';

        // 显示提交成功的提示
        alert('提交成功！');
      } catch (error) {
        console.error(error);
        alert('提交失败，请检查输入信息是否有误。');
      }
    },
    loadPoints() {
      const points = localStorage.getItem('userPoints');
      if (points) {
        this.points = parseInt(points, 10);
      }
    },
    savePoints() {
      localStorage.setItem('userPoints', this.points.toString());
    }
  }
}
</script>

<style scoped>
@import "@/assets/css/item.css";
.input-field {
  height: 26px;
}
.card {
  padding: 15px;
  margin: 5px 0;
  border-radius: 8px;
  background-color: #f0f0f0;
  overflow: auto;
  max-height: 300px;
}
form {
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 10px;
}
input {
  margin-bottom: 10px;
}
button {
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
#walletAddress, #walletBalanceTop, #pointsBalance {
  color: rgb(255, 102, 0);
}
.status {
  margin-top: 10px;
}
.shipped {
  color: red;
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>