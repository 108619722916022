<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <button class="return" @click="goBack">
          <img src="@/assets/images/left-arrow.png" alt="返回" />
        </button>
        <p>收货地址</p>
      </div>
      <div class="main">
        <div v-if="userAddresses.length > 0">
          <div v-for="(user, index) in userAddresses" :key="index" class="address-item">
            <div>
              <label :for="'address-' + index"><strong>地址信息:</strong></label>
              <textarea
                :id="'address-' + index"
                :value="addressText(user)"
                readonly
                class="address-textarea"
                @focus="$event.target.select()"
              ></textarea>
              <span v-if="user.shipped" class="shipped-status">已发货</span>
            </div>
            <button 
              :class="['button', { shipped: user.shipped }]" 
              @click="markAsShipped(user)" 
              :disabled="user.shipped"
            >
              {{ user.shipped ? '已发货' : '发货' }}
            </button>
            <button class="button" @click="deleteUserAddress(user)">删除</button>
            <button class="button" @click="copyToClipboard(user)">复制</button>
            <input type="checkbox" v-model="user.selected" class="delete-checkbox" v-if="isOwner" />
          </div>
          <button class="button" @click="batchDeleteAddresses" v-if="isOwner">批量删除</button>
        </div>
        <p v-else>暂无收货信息</p>
        <button class="button" @click="connectWallet" v-if="walletAddress === '未连接'">连接钱包</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddresss, contractABII } from '@/js/contract.js';

export default {
  name: 'delivery-addresses',
  data() {
    return {
      userAddresses: [],
      walletAddress: '未连接',
      isOwner: false
    };
  },
  methods: {
    goBack() {
      this.$router.push('/settings');
    },
    async connectWallet() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();

          // 创建合约实例
          const contract = new ethers.Contract(contractAddresss, contractABII, signer);

          // 获取合约所有者
          const owner = await contract.getOwner();
          this.isOwner = address === owner;

          this.walletAddress = address;
          if (this.isOwner) {
            await this.getAllUserAddresses(); // 自动获取所有地址
          } else {
            await this.getUserAddress(); // 获取当前用户地址信息
          }
        } catch (error) {
          console.error(error);
          alert('连接钱包失败');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async getUserAddress() {
      if (this.walletAddress !== '未连接') {
        try {
          // 创建合约实例
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(contractAddresss, contractABII, provider);

          // 调用合约的 getAddress 方法
          const [addressInfo, phoneNumber, name] = await contract.getAddress();
          const userInfo = { addressInfo, phoneNumber, name, shipped: false, selected: false };

          // 生成唯一地址哈希
          const addressHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(userInfo.addressInfo + userInfo.phoneNumber));

          // 检查 localStorage 中是否保存了 shipped 状态
          const savedShippedStatus = JSON.parse(localStorage.getItem(`shippedStatus_${addressHash}`));
          if (savedShippedStatus !== null) {
            userInfo.shipped = savedShippedStatus;
          }

          // 如果地址信息不为空，则添加到 userAddresses
          if (addressInfo) {
            this.userAddresses = [userInfo];
          }
        } catch (error) {
          console.error(error);
          alert('获取地址失败');
        }
      }
    },
    async getAllUserAddresses() {
      if (this.isOwner) {
        try {
          // 创建合约实例
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(contractAddresss, contractABII, signer);

          // 调用合约的 getAllAddresses 方法
          const allUserInfos = await contract.getAllAddresses();

          // 将获取的信息格式化为 userAddresses 数组
          this.userAddresses = allUserInfos.map((userInfo) => {
            const addressHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(userInfo.addressInfo + userInfo.phoneNumber));
            const savedShippedStatus = JSON.parse(localStorage.getItem(`shippedStatus_${addressHash}`)) || false;
            return {
              addressInfo: userInfo.addressInfo,
              phoneNumber: userInfo.phoneNumber,
              name: userInfo.name,
              shipped: savedShippedStatus,
              selected: false
            };
          });
        } catch (error) {
          console.error(error);
          alert('获取所有地址失败');
        }
      }
    },
    deleteUserAddress(user) {
      const confirmed = confirm("确定要删除该地址吗？");
      if (confirmed) {
        this.removeUserAddressFromContract(user.addressInfo, user.phoneNumber);
      }
    },
    addressText(user) {
      return `信息: ${user.addressInfo || ''}\n电话: ${user.phoneNumber || ''}\n姓名: ${user.name || ''}`;
    },
    async markAsShipped(user) {
      if (confirm("确定要标记为已发货吗？")) {
        user.shipped = true;
        const addressHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(user.addressInfo + user.phoneNumber));
        localStorage.setItem(`shippedStatus_${addressHash}`, JSON.stringify(true));
        alert("标记为已发货");
      }
    },
    copyToClipboard(user) {
      const addressInfo = this.addressText(user);
      navigator.clipboard.writeText(addressInfo).then(() => {
        alert("地址信息已复制到剪贴板");
      }, (err) => {
        console.error("Could not copy text: ", err);
        alert("复制失败，请重试");
      });
    },
    async removeUserAddressFromContract(addressInfo, phoneNumber) {
      // 创建合约实例
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddresss, contractABII, signer);

      try {
        // 调用合约的 deleteAddressByInfo 方法
        const tx = await contract.deleteAddressByInfo(addressInfo, phoneNumber);
        await tx.wait(); // 等待交易确认

        // 从本地数组中删除该地址
        this.userAddresses = this.userAddresses.filter(user => 
          user.addressInfo !== addressInfo || user.phoneNumber !== phoneNumber
        );

        // 清除对应的 shipped 状态
        const addressHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(addressInfo + phoneNumber));
        localStorage.removeItem(`shippedStatus_${addressHash}`);

        alert("地址已从合约中删除");
      } catch (error) {
        console.error(error);
        alert("删除地址失败");
      }
    },
    batchDeleteAddresses() {
      const confirmed = confirm("确定要批量删除选中的地址吗？");
      if (confirmed) {
        const selectedAddresses = this.userAddresses.filter(user => user.selected);
        selectedAddresses.forEach(async (user) => {
          await this.removeUserAddressFromContract(user.addressInfo, user.phoneNumber);
        });
        this.userAddresses = this.userAddresses.filter(user => !user.selected);
        alert("选中的地址已批量删除");
      }
    }
  },
  mounted() {
    // 连接钱包并获取当前用户的信息
    this.connectWallet();
  }
};
</script>

<style scoped>
@import "@/assets/css/style.css";

.main {
  padding-top: 50px;
  height: calc(100vh - 100px); /* 确保内容在屏幕内并有滚动余地 */
  overflow-y: auto; /* 添加垂直滚动条 */
}

.top button {
  margin-right: 10px;
}

.button {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button.shipped {
  background-color: red;
}

.address-item {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.address-textarea {
  width: 100%;
  height: 60px; /* 增加高度以适应更多信息 */
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  background-color: #f9f9f9;
}

.wallet-info {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.shipped-status {
  display: block;
  color: red;
  font-weight: bold;
  margin-top: 5px;
}

.delete-checkbox {
  margin-left: 5px; /* 勾选框左边距 */
}
</style>